import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {CardWrapper, Label, LabelWrapper, Logo, LogoContainer, MainWrapper, PolicyText, Title} from './signup.style';
import HEX from '../../assets/HEX_Shadow.svg';
import EditField from '../../components/edit/edit';
import Button from '../../components/button/button';
import NewUserButton from '../../components/new_user_button/button';
import {useDispatch, useSelector} from "react-redux";
import {signup} from "../../redux/actions/authActions";

const Signup = () => {
    const [data, setData] = useState({
        name: "",
        email: "",
        password: "",
        phone: "",
        account_type: "USER"
    })
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const history = useHistory();
    const handleChange = (e) => {
        e.persist();
        setData(prev => ({...prev, [e.target.name]: e.target.value}))

    }

    useEffect(() => {
        if (auth.isAuthenticated === true) {
            history.push('dashboard');

        }
    });

    return (
        <div style={{paddingTop: 50}}>
            <MainWrapper>
                <CardWrapper>
                    <LogoContainer>
                        <div style={{
                            height: '50px',
                            width: '45px'
                        }}>
                            <Logo src={HEX}/>
                        </div>
                        <LabelWrapper>
                            <Label>Hex</Label>
                            <Label style={{color: '#AAAAAA'}}>Account</Label>
                        </LabelWrapper>
                    </LogoContainer>
                    <Title>Create Account</Title>
                    <EditField onChange={(e) => handleChange(e)} type='name' placeholder='Full Name'/>
                    <EditField onChange={(e) => handleChange(e)} type='email' placeholder='Email address'/>
                    <EditField onChange={(e) => handleChange(e)} type='password' placeholder='Password'/>
                    <EditField onChange={(e) => handleChange(e)} type='phone' placeholder='Phone Number'/>
                    <Button buttonName='Continue' onClick={() => {
                        dispatch(signup(data))
                    }}/>
                    <PolicyText>
                        By Submitting this form, you agree to our Terms of Service and Privacy Policy.
                    </PolicyText>
                    <div style={{
                        justifyContent: 'center',
                        display: 'flex',
                        marginTop: '-15px'
                    }}>
                        <NewUserButton
                            userText='Already have an account?'
                            buttonName='Log in'
                            onClick={() => {
                                history.goBack();
                            }}
                        />
                    </div>
                </CardWrapper>
            </MainWrapper>
        </div>
    );

}

export default Signup;
