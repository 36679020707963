import React, {useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import HexLogo from '../components/logo/logo';
import BottomBar from '../components/bottom/bottom';
import NewUserButton from '../components/new_user_button/button';
import {
    MainWrapper,
    RightWrapper,
    LeftWrapper,
    LoginWrapper,
    Title,
    LoginButton,
    RegisterWrapper,
    NewUserLabel,
    SignupButton
} from './index.style';
import {useSelector} from "react-redux";

const Index = () => {
    const auth = useSelector(state => state.auth);
    const history = useHistory();

    const goLogin = () => {
        history.push('login');
    }
    useEffect(() => {
        if (auth.isAuthenticated === true) {
            history.push('dashboard');

        }
    });


    return (
        <div className="App">
            <MainWrapper>
                <RightWrapper>
                    <HexLogo/>
                    <LoginWrapper>
                        <Title>Manage your Hex services</Title>
                        <LoginButton type="button" onClick={goLogin}>Login With Hexaccount</LoginButton>
                        <NewUserButton
                            userText='New User?'
                            buttonName='Create Account'
                            onClick={() => {
                                history.push('signup');
                            }}
                        />
                        <BottomBar />
                    </LoginWrapper>
                </RightWrapper>
                <LeftWrapper>
                </LeftWrapper>
            </MainWrapper>
        </div>
    );
}

export default Index;
