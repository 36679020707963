import * as types from '../types/types';

const initialState = {
    userMilestones: null,
    loading: false,
    paymentSession: ""
};

export default function (state = initialState, action) {
    switch (action.type) {
        case types.MILESTONE_LOADING:
            return {...state, loading: true};
        case types.GET_USER_MILESTONES:
            return {
                ...state,
                userMilestones: action.payload.data,
                loading: false
            };
        case types.CREATE_MILESTONE:
            return {
                ...state,
                selectedMilestone: action.payload,
                loading: false
            };
        case types.CREATE_PAYMENT_SESSION:
            return {
                ...state,
                paymentSession: action.payload,
                loading: false
            };
        case types.CLEAR_PAYMENT_SESSION:
            return {
                ...state,
                paymentSession: "",
                loading: false
            };
        case types.GET_MILESTONE_BY_ID:
            return {
                ...state,
                selectedMilestone: action.payload,
                loading: false
            };
        default:
            return state;
    }
}
