import * as types from '../types/types';

import isEmpty from '../../utils/validation/is-empty';


const initialState = {
    isAuthenticated: false,
    user: {},
    userdata: {},
    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case types.PROFILE_LOADING:
            return {...state, loading: true};
        case types.SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload,
                loading: false
            };
        case types.REGISTER_USER:
            return {...state, registerdata: action.payload};
        case types.GET_PROFILE_BY_ID:
            return {
                ...state,
                userdata: action.payload,
                loading: false
            };
        case types.GET_CUSTOMER_PORTAL:
            return {...state, portal: action.payload.url, loading: false};
        case types.CHANGE_PASSWORD:
            return {...state, changepassword: action.payload};
        case types.LOGOUT:
            return initialState;
        default:
            return state;
    }
}
