import styled from 'styled-components';
import {colors} from '../../colors';

const osType = navigator.platform.substring(0, 3);


export const TableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: white;
    padding: 20px;
    padding-left: 35px;
    padding-right: 35px;
    box-shadow:
        0 2px 2px rgba(0,0,0, 0.03),
        0 4px 4px rgba(0,0,0, 0.03),
        0 8px 8px rgba(0,0,0, 0.03),
        0 16px 16px rgba(0,0,0, 0.03);

    @media (max-width: 450px) {
        padding: 10px;
    }
`;

export const TableHeaderWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    font-family: 'GorditaMedium';
`;

export const TableHeader1 = styled.div`
    border-bottom: solid 1px #ECECED;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    width: 15%;
    color: ${colors.grayLightColor};

    @media (max-width: 450px) {
        word-break: break-all;
        font-size: 10px;
    }
`;

export const TableHeader2 = styled.div`
    border-bottom: solid 1px #ECECED;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    width: 25%;
    color: ${colors.grayLightColor};

    @media (max-width: 450px) {
        word-break: break-all;
        font-size: 10px;
    }
`;

export const TableHeader3 = styled.div`
    border-bottom: solid 1px #ECECED;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    width: 20%;
    justify-content: flex-start;
    color: ${colors.grayLightColor};

    @media (max-width: 450px) {
        font-size: 10px;
    }
`;

export const TableHeader4 = styled.div`
    border-bottom: solid 1px #ECECED;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    width: 15%;
    justify-content: flex-start;
    color: ${colors.grayLightColor};

    @media (max-width: 450px) {
        word-break: break-all;
        font-size: 10px;
    }
`;

export const TableHeader5 = styled.div`
    border-bottom: solid 1px #ECECED;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    width: 15%;
    justify-content: center;
    color: ${colors.grayLightColor};

    @media (max-width: 450px) {
        word-break: break-all;
        font-size: 10px;
    }
`;

export const TableHeader6 = styled.div`
    border-bottom: solid 1px #ECECED;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    width: 10%;
    justify-content:center;
    color: ${colors.grayLightColor};

    @media (max-width: 450px) {
        word-break: break-all;
        font-size: 10px;
    }
`;

export const TableHeader7 = styled.div`
    border-bottom: solid 1px #ECECED;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    width: 15%;
    color: ${colors.grayLightColor};

    @media (max-width: 450px) {
        word-break: break-all;
        font-size: 10px;
    }
`;

export const TableBodyWrapper = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    font-family: 'GorditaMedium';
    font-size: 12px;
    height: 50px;

    @media (max-width: 1030px) {
        height: 70px;
    }

    @media (max-width: 800px) {
        height: 85px;
    }

    @media (max-width: 450px) {
        height: 100px;
    }
`;


export const Col1 = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    width: 15%;
    padding-top: 5px;
    color: ${colors.textColor};
`;

export const Col2 = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    width: 25%;
    padding-top: 5px;
    color: ${colors.textColor};
`;

export const Col3 = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    width: 20%;
    padding-top: 5px;
    justify-content: flex-start;
    color: ${colors.textColor};

`;

export const Col4 = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    width: 15%;
    padding-top: 5px;
    justify-content: flex-start;
    color: ${colors.textColor};

`;

export const Col5 = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    width: 15%;
    padding-top: 5px;
    justify-content: center;
    color: ${colors.textColor};

`;

export const Col6 = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    width: 10%;
    padding-top: 5px;
    justify-content: center;
    color: ${colors.textColor};
`;

export const LabelWrapper = styled.div`
    display: flex;
    width: 64px;
    height: 25px;
    align-items: center;
    justify-content: center;
    background-color: ${colors.activeProjectBackColor};
    border-radius: 13px;
`;

export const LabelCloseWrapper = styled.div`
    display: flex;
    width: 64px;
    height: 25px;
    align-items: center;
    justify-content: center;
    background-color: ${colors.closeProjectBackColor};
    border-radius: 13px;
`;

export const Label = styled.label`
    font-size: 10px;
    color: ${colors.activeProjectColor};
    font-family: 'GorditaMedium';
    margin-top: ${osType === 'Mac' ? '4px' : '0px'}
`;

export const CloseLabel = styled.label`
    font-size: 10px;
    color: ${colors.closeProjectColor};
    font-family: 'GorditaMedium';
    margin-top: ${osType === 'Mac' ? '4px' : '0px'}
`;

export const DownloadButton = styled.button`
    margin-right: 15px;
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;

    @media (max-width: 450px) {
        margin-right: 0px;
    }
`;