import React from 'react';
import {
    BottomWrapper,
    Copyright,
    Text,
    Payments,
    Bottom
} from './bottom.style';
import Payment from '../../assets/payments.png';

class BottomBar extends React.Component {
    render() {
        return (
            <Bottom>
                <BottomWrapper>
                    <Copyright>Copyright @ 2020 Hexmount.io</Copyright>
                    <Text>support@hexmount.io</Text>
                    <Text>Terms and Policies</Text>
                </BottomWrapper>
                {
                    this.props.isVisible ?
                        <Payments>
                            <img src={Payment} />
                        </Payments>
                        : null
                }
            </Bottom>
        );
    }
}

export default BottomBar;
