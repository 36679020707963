// Dashboard stuff
export const DATA_LOADING = 'DATA_LOADING';

// App
export const GET_APP_STATE = 'GET_APP_STATE';
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

// User Stuff

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_PROFILE = 'GET_PROFILE';
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const PROFILE_NOT_FOUND = 'PROFILE_NOT_FOUND';
export const CLEAR_CURRENT_PROFILE = 'CLEAR_CURRENT_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const GET_PROFILE_BY_ID = 'GET_PROFILE_BY_ID';
export const GET_CUSTOMER_PORTAL = 'GET_CUSTOMER_PORTAL';


export const REGISTER_USER = 'REGISTER_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const LOGOUT = 'LOGOUT';

// Projects Stuff
export const GET_USER_PROJECTS = 'GET_USER_PROJECTS';
export const GET_PROJECT_MILESTONES = 'GET_PROJECT_MILESTONES';
export const GET_PROJECT_BY_ID = 'GET_PROJECT_BY_ID';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const PROJECT_LOADING = 'PROJECT_LOADING';
export const SELECT_PROJECT = 'SELECT_PROJECT';

// Proposal Stuff
export const GET_USER_PROPOSALS = 'GET_USER_PROPOSALS';
export const GET_PROPOSAL_BY_ID = 'GET_PROPOSAL_BY_ID';
export const CREATE_PROPOSAL = 'CREATE_PROPOSAL';
export const UPDATE_PROPOSAL = 'UPDATE_PROPOSAL';
export const DELETE_PROPOSAL = 'DELETE_PROPOSAL';
export const PROPOSAL_LOADING = 'PROPOSAL_LOADING';

// Milestone Stuff
export const GET_MILESTONE_BY_ID = 'GET_MILESTONE_BY_ID';
export const CREATE_MILESTONE = 'CREATE_MILESTONE';
export const UPDATE_MILESTONE = 'UPDATE_MILESTONE';
export const DELETE_MILESTONE = 'DELETE_MILESTONE';
export const MILESTONE_LOADING = 'MILESTONE_LOADING';
export const GET_USER_MILESTONES = 'GET_USER_MILESTONES';
export const CREATE_PAYMENT_SESSION = 'CREATE_PAYMENT_SESSION';
export const CLEAR_PAYMENT_SESSION = 'CLEAR_PAYMENT_SESSION';
