import React from 'react';
import {
    TableWrapper,
    TableHeaderWrapper,
    TableHeader1,
    TableHeader2,
    TableHeader3,
    TableHeader4,
    TableHeader5,
    TableHeader6,
    LabelWrapper,
    Label,
    LabelCloseWrapper,
    CloseLabel,
    TableBodyWrapper,
    Row,
    Col1,
    Col2,
    Col3,
    Col4,
    Col5,
    Col6,
    DownloadButton
} from './table.style';
import { Download } from '@styled-icons/bootstrap/Download';

class Button extends React.Component {

    render() {
        return (
            <TableWrapper>
                <TableHeaderWrapper>
                    <TableHeader1>Date</TableHeader1>
                    <TableHeader2>Milestone</TableHeader2>
                    <TableHeader3>Payment method</TableHeader3>
                    <TableHeader4>Payment status</TableHeader4>
                    <TableHeader5>Amount</TableHeader5>
                    <TableHeader6>Download</TableHeader6>
                </TableHeaderWrapper>
                <TableBodyWrapper>
                    {
                        this.props.data ? this.props.data.map((item, index) => {
                            return (
                            <Row key={index}>
                                <Col1>{item.date}</Col1>
                                <Col2>{item.milestone}</Col2>
                                <Col3>{item.method}</Col3>
                                <Col4>
                                    {
                                        item.status ?
                                        <LabelWrapper>
                                            <Label>Active</Label>
                                        </LabelWrapper> :
                                        <LabelCloseWrapper>
                                            <CloseLabel>Closed</CloseLabel>
                                        </LabelCloseWrapper>
                                    }
                                </Col4>
                                <Col5>{item.amount}</Col5>
                                <Col6>
                                    <DownloadButton>
                                        <Download size={18} />
                                    </DownloadButton>
                                </Col6>
                            </Row>);
                        }) :
                        <Row>
                            There is no data
                        </Row>
                    }
                </TableBodyWrapper>
            </TableWrapper>
        );
    }
}

export default Button;
