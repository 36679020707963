import styled from 'styled-components';
import { colors } from '../../colors';

export const ButtonWrapper = styled.button`
    height: 49px;
    font-size: 16px;
    color: white;
    background-color: ${colors.mainColor};
    outline: none;
    border-radius: 5px;
    border-width: 0px;
    cursor: pointer;
    margin-top: 15px;
    font-family: 'GorditaMedium';
    padding-top: 10px;
`;