import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  MainWrapper,
  CardWrapper,
  LogoContainer,
  LabelWrapper,
  Logo,
  Label,
  Title
} from './verify.style';
import { colors } from '../../colors';
import HEX from '../../assets/HEX_Shadow.svg';
import EditField from '../../components/edit/edit';
import Button from '../../components/button/button';
import PasswordStrengthBar from 'react-password-strength-bar';
import OutlineButton from '../../components/outline_button/button';
import NewUserButton from '../../components/new_user_button/button';
import VerificationInput from 'react-verification-input';

class Verify extends React.Component {
  // history = useHistory();
  timer = 0;


  constructor(props) {
    super(props);
    this.state = {
      password: '',
      verifyCode: ''
    }
  }

  async componentDidMount() {
    try {
      this.timer = setInterval(async () => {
        var val = '';
        for(let i = 0; i < 6; i++) {
         var verify = document.getElementById(`field-${i}`).innerHTML;
          if(verify !== '&nbsp;' && verify !== null) {
            val = val + verify;
            this.setState({
              verifyCode: val
            })
          }
          if(this.state.verifyCode.length === 6) {
            this.props.history.push('password');
          }
        }
        console.log('verify ', val);
      }, 1000);
    } catch(e) {
      console.log(e);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    return (
      <div style={{paddingTop: 50}}>
        <MainWrapper>
          <CardWrapper>
            <LogoContainer>
            <div style={{
              height: '50px',
              width: '45px'
            }}>
              <Logo src={HEX} />
            </div>
              <LabelWrapper>
                <Label>Hex</Label>
                <Label style={{ color: '#AAAAAA' }}>Account</Label>
              </LabelWrapper>
            </LogoContainer>
            <Title>Verify your account</Title>
            <div style={{
              textAlign: 'center',
              color: colors.textColor,
              fontSize: '14px',
              marginBottom: '20px',
              fontFamily: 'GorditaMedium'
            }}>
              Enter the verification code sent to <br/>
              <b style={{
                fontSize: '14px',
                fontFamily: 'GorditaBold',
                color: 'black',
                marginLeft: '5px',
                lineHeight: '25px'
              }}
              >meid@hexmount.io</b>
              <br/>
              Check your spam folder if you can't find our email
              </div>
              <div style={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex'
              }}>
                <VerificationInput
                  removeDefaultStyles
                  placeholder=''
                  container={{
                    className:'container'
                  }}
                  characters={{
                    className: 'characters'
                  }}
                  character={{
                    className: 'character',
                    classNameInactive: 'character--inactive',
                    classNameSelected: 'character--selected'
                  }}
                />
              </div>
              <div style={{
                justifyContent: 'center',
                display: 'flex',
                marginTop: '15px'
              }}>
                <NewUserButton
                    userText="Didn't get the code?"
                    buttonName='Try again'
                    onClick={() => {
                      // history.goBack();
                    }}
                />
              </div>
          </CardWrapper>
        </MainWrapper>
      </div>
    );
  }

}

export default Verify;
