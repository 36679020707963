import styled from 'styled-components';
import { colors } from '../colors';

const deviceWidth = window.innerWidth;
const deviceHeight = window.innerHeight;
const mobileWidth = '450px';

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

export const RightWrapper = styled.div`
    background-color: ${colors.backgroundColor};
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    color: white;
    width: 60%;
    min-height: 100vh - 30px;
    padding-left: 220px;
    padding-top: 30px;
    padding-bottom: 30px;

    @media (max-width: 450px) {
        width: 100%;
        padding-left: 30px;
        min-height: ${deviceHeight - 60}px;
    }

    @media (max-width: 768px) {
        padding-left: 30px;
        min-height: ${deviceHeight - 60}px;
    }
`;

export const LeftWrapper = styled.div`
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    width: 40%;

    @media (max-width: 450px) {
      display: none;
      width: 0px;
    }
`;

export const LoginWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 300px;

    @media (max-width: 450px) {
        padding-top: 200px;
    }
`;

export const Title = styled.label`
    font-family: 'GorditaMedium';
    font-style: normal;
    font-size: 38px;
    color: ${colors.textColor};
    text-align: left;
    margin-bottom: 20px;

    @media (max-width: 450px) {
        line-height: 40px;
    }

    @media (max-width: 768px) {
        line-height: 40px;
    }
`;

export const LoginButton = styled.button`
    width: 267px;
    height: 49px;
    font-size: 16px;
    color: white;
    font-family: 'GorditaRegular';
    background-color: ${colors.mainColor};
    outline: none;
    border-radius: 5px;
    border-width: 0px;
    cursor: pointer;
    margin-top: 0px;
    padding-top: 10px;
`;

