import React from 'react';
import {ContentWrapper, MainWrapper,} from './dashboard.style';
import SidebarComponent from '../../components/sidebar/SidebarComponent';
import Header from '../../components/header/header';
import Card from '../../components/card/card';
import DataTable from '../../components/table/table';
import BottomBar from '../../components/bottom/bottom';
import {getUserProjects} from "../../redux/actions/projectActions";
import {GetUserById, GetUserPortal} from "../../redux/actions/authActions"
import {connect} from "react-redux";
import {Milestones} from "../../pages/milestone/milestone";

const billingData = [
    {
        id: '1',
        date: 'Aug 15th, 2020',
        milestone: 'Rahma First Payment',
        method: 'Mastercard 9823***',
        status: true,
        amount: '5000$',
    },
    {
        id: '2',
        date: 'Aug 15th, 2020',
        milestone: 'Rahma Second Payment',
        method: 'Mastercard 9823***',
        status: true,
        amount: '3000$',
    },
    {
        id: '3',
        date: 'Aug 15th, 2020',
        milestone: 'Sajda First Payment',
        method: 'Mastercard 9823***',
        status: false,
        amount: '1000$',
    },
    {
        id: '4',
        date: 'Aug 15th, 2020',
        milestone: 'Sajda Done Payment',
        method: 'Mastercard 9823***',
        status: true,
        amount: '2000$',
    },
    {
        id: '5',
        date: 'Aug 15th, 2020',
        milestone: 'Foodle Payment',
        method: 'Mastercard 9823***',
        status: false,
        amount: '9000$',
    },
]

class Dash extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItem: 'Projects & Services',
            selectedProject: '',

        };

    }

    componentDidMount() {
        window.addEventListener('resize', this.resize);
        if (this.props.auth.isAuthenticated !== true) {
            this.props.history.push('signin');

        }
        const {user} = this.props.auth
        this.props.GetUserById({account_id: user.UserId})
        this.props.GetUserPortal({customer_id: user.StripeId})
        this.props.getUserProjects({customer_id: user.StripeId})

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    selectMile =() => {
        this.setState({selectedItem: "Milestones"})

    }
    resize = () => this.forceUpdate();

    handleMileChange(value) {
        console.log(`selected ${value}`);
    }

    render() {
        const {selectedItem} = this.state;
        const {selectedProject} = this.state;
        // const {loading} = this.props.projects
        const {userProjects} = this.props.projects

        return (
            <div>
                <MainWrapper>
                    <SidebarComponent
                        selectedItem={selectedItem}
                        onChange={(selectedItem) => this.setState({selectedItem})}/>

                    <Header pageName={selectedItem}/>
                    <ContentWrapper>
                        {
                            userProjects !== null &&
                            selectedItem === 'Projects & Services' &&
                            userProjects.map((item, index) => {
                                return <Card
                                    key={index}
                                    selectedProject={selectedProject}
                                    projectName={item.name}
                                    status={item.status}
                                    gitbook={item.gitbook_link}
                                    selectMile={this.selectMile}
                                />
                            })
                        }

                        {
                            selectedItem === 'Milestones' &&
                            <>
                                <Milestones data={billingData} selectedProject={this.state.selectedProject}/>
                            </>
                        }
                        {
                            selectedItem === 'Proposals' &&
                            <DataTable
                                data={billingData}
                            />
                        }
                        {
                            selectedItem === 'Billing' &&
                            <DataTable
                                data={billingData}
                            />
                        }
                        <BottomBar isVisible/>
                    </ContentWrapper>
                </MainWrapper>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    projects: state.projects
});


export default connect(mapStateToProps, {getUserProjects, GetUserById, GetUserPortal})(Dash);
