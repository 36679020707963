import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";

const StripeCheckout = () => {
  const params = useParams();
  console.log(params);
  const { id } = params;
  useEffect(() => {
    const asyncFunc = async () => {
      let sessionId =
        id ||
        "cs_test_c0L1LQZQcXD6UE8niUoE534Gwt9ccYPTXICyiEQI7PvDW9O2H3umg7fYzW";
      const stripeKey = 'pk_live_51H7mzcEhJzqEYyEzUyMTEcuy8sFXLLZMDhUzZVBWDUUdkgRDvTUjnmIaeaEHZCA1uYHeXgKeLlVRmSHLeWrcLKs400vkjuAyQ4'
      const stripe = await loadStripe(stripeKey);
      await stripe.redirectToCheckout({
        sessionId,
      });
    };
    asyncFunc();
  }, []);
  return <div />;
};

export default StripeCheckout;