import React from 'react';
import {
    CloseLabel,
    Col1,
    Col2,
    Col3,
    Col4,
    Col5,
    Col6,
    DownloadButton,
    Label,
    LabelCloseWrapper,
    LabelWrapper,
    Row,
    TableBodyWrapper,
    TableHeader1,
    TableHeader2,
    TableHeader3,
    TableHeader4,
    TableHeader5,
    TableHeader6,
    TableHeaderWrapper,
    TableWrapper
} from './table.style';
import {loadStripe} from '@stripe/stripe-js';
import {useDispatch, useSelector} from "react-redux";
import {CreateMilestoneLink} from "../../redux/actions/milestoneActions";
import {CLEAR_PAYMENT_SESSION, CREATE_PAYMENT_SESSION} from "../../redux/types/types";

const stripePromise = loadStripe('pk_live_51H7mzcEhJzqEYyEzUyMTEcuy8sFXLLZMDhUzZVBWDUUdkgRDvTUjnmIaeaEHZCA1uYHeXgKeLlVRmSHLeWrcLKs400vkjuAyQ4');


const Milestones = (props) => {
    const auth = useSelector(state => state.auth);
    const mile = useSelector(state => state.milestones);
    const dispatch = useDispatch();
    const SetupPayment = async (item) => {
        const stripe = await stripePromise;
        const data = {
            customer_id: auth.user.StripeId,
            product_name: item.milestone_name,
            amount: Number(item.amount + '00'),
            currency: item.currency,
        }
        dispatch(CreateMilestoneLink(data))
        const result = await stripe.redirectToCheckout({
            sessionId: mile.paymentSession.id
        });
        dispatch({
            type: CLEAR_PAYMENT_SESSION
        })
// clear payment session
        if (result.error) {
            dispatch({
                type: CLEAR_PAYMENT_SESSION
            })
        }
    }

    return (
        <TableWrapper>
            <TableHeaderWrapper>
                <TableHeader1>Due Date</TableHeader1>
                <TableHeader2>Milestone Name</TableHeader2>
                <TableHeader3>Description</TableHeader3>
                <TableHeader4>Payment status</TableHeader4>
                <TableHeader5>Amount</TableHeader5>
                <TableHeader6>Pay</TableHeader6>

            </TableHeaderWrapper>
            <TableBodyWrapper>
                {
                    props.data ? props.data.map((item, index) => {
                            return (
                                <Row key={index}>
                                    <Col1>{item.date}</Col1>
                                    <Col2>{item.milestone_name}</Col2>
                                    <Col3>{item.description}</Col3>
                                    <Col4>
                                        {
                                            item.status === 'Paid' &&
                                            <LabelWrapper>
                                                <Label>Paid</Label>
                                            </LabelWrapper>}
                                        {item.status === 'Active' &&

                                        <LabelCloseWrapper>
                                            <CloseLabel>Pending</CloseLabel>
                                        </LabelCloseWrapper>
                                        }
                                    </Col4>
                                    <Col5>{item.amount + ''} {item.currency}</Col5>
                                    <Col6>

                                        {item.status === 'Active' &&
                                         <DownloadButton onClick={() => {
                                            SetupPayment(item)
                                        }}>
                                            Pay Now
                                        </DownloadButton>}
                                    </Col6>
                                </Row>);
                        }) :
                        <Row>
                            There is no data
                        </Row>
                }
            </TableBodyWrapper>
        </TableWrapper>
    );
}


export default Milestones;
