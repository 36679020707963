import React from 'react';

import {GetUserById, GetUserPortal} from "../../redux/actions/authActions";
import {connect} from "react-redux"
import {AvatarContainer, HeaderWrapper, HelpContainer, Label, TitleWrapper, UserWrapper} from "./header.style";
import {HelpCircle} from "@styled-icons/boxicons-solid/HelpCircle";
import {PersonCircle} from "@styled-icons/bootstrap/PersonCircle";
import MileSelect from "../milestone/select";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            account: this.props.auth.userdata,
            loading: this.props.auth.loading,
        }
        const {user} = this.props.auth
        // this.props.GetUserById({account_id: user.UserId})
        // this.props.GetUserPortal({customer_id: user.StripeId})
    }

    componentDidMount() {
        console.log('auth : ', this.props.auth)
        this.setState({account: this.props.auth.userdata})
    }

    goToStripe = () => {
        const {portal} = this.props.auth
        window.location.replace(portal)
    }


    render() {
        // get loading data

        const {portal} = this.props.auth
        const {email} = this.state.account

        return (
            <HeaderWrapper>
                <TitleWrapper>{this.props.pageName}</TitleWrapper>

                <UserWrapper>
                    <HelpContainer>
                        <HelpCircle size={20}/>
                        <Label>Help</Label>
                    </HelpContainer>
                    <AvatarContainer>
                        <PersonCircle onClick={this.goToStripe} size={35}/>
                        <Label href={portal}>{email === undefined ? this.props.auth.user.User_Email : email}</Label>
                    </AvatarContainer>
                </UserWrapper>
            </HeaderWrapper>
        );

    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, {GetUserById, GetUserPortal})(Header);
