import styled from 'styled-components';
import { colors } from '../../colors';


export const RegisterWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    align-items: center;
`;

export const NewUserLabel = styled.label`
    font-size: 14px;
    font-family: 'GorditaMedium';
    color: ${colors.grayDarkColor};
`;

export const NewButton = styled.button`
    margin-left: 5px;
    font-size: 14px;
    font-family: 'GorditaMedium';
    background: transparent;
    border: none;
    color: ${colors.mainColor};
    outline: none;
    cursor: pointer;
`;
