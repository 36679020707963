import * as types from '../types/types';

const initialState = {

    userProjects: [],
    selectedProject: {},
    selectedProjectMilestones: {},
    loading: false

};

export default function (state = initialState, action) {
    switch (action.type) {
        case types.PROJECT_LOADING:
            return {...state, loading: true};
        case types.GET_USER_PROJECTS:
            return {
                ...state,
                userProjects: action.payload.data,
                loading: false
            };
        case types.CREATE_PROJECT:
            return {
                ...state,
                selectedProject: action.payload,
                loading: false
            };
        case types.GET_PROJECT_BY_ID:
            return {
                ...state,
                selectedProject: action.payload,
                loading: false
            };
        case types.GET_PROJECT_MILESTONES:
            return {
                ...state,
                selectedProjectMilestones: action.payload,
                loading: false
            };
        case types.SELECT_PROJECT:
            return {
                ...state,
                selectedProject: action.payload,
                loading: false
            };
        case types.UPDATE_PROJECT:
            return action.payload;
        default:
            return state;
    }
}
