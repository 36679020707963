import React from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {Button, CardWrapper, Logo, LogoContainer, MainWrapper, SpinWrap, Text, Title} from "../success/success.style";
import HEX from "../../assets/HEX_Shadow.svg";
import Card from "../../assets/Card.svg";

import {motion} from "framer-motion"

const Lottie1 = require(`../../assets/tick.json`);


const Success = () => {
    const auth = useSelector(state => state.auth);
    const loading = auth.loading;
    const dispatch = useDispatch();
    const history = useHistory();
    const defaultOptions = {
        loop: false,
        autoplay: true,
        autoSize: true,
        animationData: Lottie1,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    if (loading) {
        return (
            <SpinWrap>
                {/*<Spin size="large"/>*/}
            </SpinWrap>
        )
    } else {
        return (
            <div
                style={{paddingTop: 50}}>
                <MainWrapper>
                    <CardWrapper>
                        <LogoContainer>
                            <div style={{
                                justifyContent: 'center',
                                display: 'flex',
                            }}>
                                <Logo src={HEX}/>
                            </div>


                        </LogoContainer>
                        <div style={{
                            justifyContent: 'center',
                            display: 'flex',
                        }}>
                            <motion.img
                                style={{
                                    height: 300,
                                    width: 300
                                }}
                                whileHover={{scale: 0.9}}
                                whileTap={{scale: 0.9}}
                                src={Card}/>
                        </div>
                        <Title>Thanks For That!!</Title>
                        <Text>Your payment was successful! You can</Text>
                        <Text>now continue using Hexservices</Text>
                        <Button onClick={() => history.push('Dashboard')}>
                            Go To Dashboard
                        </Button>


                    </CardWrapper>
                </MainWrapper>
            </div>
        );
    }
}
export default Success;