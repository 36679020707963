import React from 'react';
import {
    LogoWrapper,
    Logo,
    Label
} from './logo.style';
import HEX from '../../assets/HEX.svg';

function HexLogo() {
  return (
    <LogoWrapper>
      <Logo src={HEX} />
      <Label>Hexmount</Label>
    </LogoWrapper>
  );
}

export default HexLogo;
