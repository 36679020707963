import React from 'react';
import {BrowserRouter as Router, Route, Switch,} from "react-router-dom";
import './App.css';
import Home from './pages/index';
import Login from './pages/login/login';
import Signup from './pages/signup/signup';
import Reset from './pages/reset/reset';
import Password from './pages/password/password';
import Verify from './pages/verify/verify';
import Dashboard from './pages/dashboard/dashboard';
import Success from "./pages/success/success";
import Checkout from "./pages/milestone/checkout";


function App() {
    return (
        <Router>
            <Switch>
                <Route path="/login" component={Login}/>
                <Route path="/signup" component={Signup}/>
                <Route path="/password" component={Password}/>
                <Route path="/dashboard" component={Dashboard}/>
                <Route path="/project/milestones" component={Dashboard}/>
                <Route path="/success" component={Success}/>
                <Route path="/reset" component={Reset}/>
                <Route path="/verify" component={Verify}/>
                <Route path="/stripe-checkout/:id" component={Checkout} />
                <Route exact path="/" component={Home}/>
            </Switch>
        </Router>
    );
}

export default App;
