import styled from 'styled-components';
import { colors } from '../../colors';

const width = window.innerWidth - 30;

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: calc(100% - 355px);
    align-items: center;
    position: fixed;
    padding-left: 50px;
    padding-right: 50px;
    height: 40px;
    top: 0px;
    right: 0px;
    padding-top: 30px;
    background-color: ${colors.backgroundColor};
    justify-content: space-between;

    @media (max-width: 768px) {
        width: calc(100% - 175px);
    }

     @media (max-width: 450px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        display: flex;
        width: calc(100% - 110px);
        padding-top: 30px;
        height: 100px;
    }
`;

export const TitleWrapper = styled.label`
    font-size: 22px;
    font-family: 'GorditaMedium';

    @media (max-width: 450px) {
        margin-bottom: 10px;
    }
`;

export const UserWrapper = styled.div`
    display: flex;
`;

export const HelpContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const AvatarContainer = styled.div`
    display: flex;
    margin-left: 30px;
    align-items: center;
`;

export const Label = styled.a`
    margin-left: 8px;
    margin-top: 10px;
    font-family: 'GorditaMedium';
    font-size: 12px;
`;