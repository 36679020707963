import { combineReducers } from 'redux';
import authReducer from './authReducer';

import errorReducer from './errorReducer';
import projectReducer from "./projectReducer";
import milestoneReducer from "./milestoneReducer";

export default combineReducers({
	auth: authReducer,
	projects: projectReducer,
	// proposals: projectReducer,
	milestones: milestoneReducer,
	error: errorReducer,

	// user: userReducer,

});
