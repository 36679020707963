import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import MileDataTable from "../../components/milestone/table";
import {GetUserMilestones} from "../../redux/actions/milestoneActions";
// import {Spin} from "antd";
import {MainWrapper} from "./milestone.style";
import MileSelect from "../../components/milestone/select";

export const Milestones = (props) => {
    const [data, setData] = useState(useSelector(state => state.milestones))
    const miles = useSelector(state => state.milestones);
    const auth = useSelector(state => state.auth);
    const loading = miles.loading;
    const dispatch = useDispatch();
    // handleClick()


    useEffect(() => {
        dispatch(GetUserMilestones({customer_id: auth.user.StripeId}))
    }, [data]);


    if (loading) {
        return (<div/>)
    } else {
        return (
            <>
                {/*<MainWrapper>*/}
                {/*    <MileSelect/>*/}
                {/*</MainWrapper>*/}
                <MileDataTable
                    data={miles.userMilestones}
                />
            </>
        )
    }
}

