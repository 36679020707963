import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {loginUser} from '../../redux/actions/authActions'
import {CardWrapper, Label, LabelWrapper, Logo, LogoContainer, MainWrapper, SpinWrap, Title} from './login.style';
import HEX from '../../assets/HEX_Shadow.svg';
import EditField from '../../components/edit/edit';
import Button from '../../components/button/button';
import OutlineButton from '../../components/outline_button/button';
import NewUserButton from '../../components/new_user_button/button';

// import {Spin} from 'antd';

const Login = () => {
    const [data, setData] = useState({
        email: "",
        password: ""
    })
    const auth = useSelector(state => state.auth);
    const selector = useSelector(state => state);
    const loading = auth.loading;
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (auth.isAuthenticated === true) {
            history.push('dashboard');
        }
    });

    const handleChange = (e) => {
        e.persist();
        setData(prev => ({...prev, [e.target.name]: e.target.value}))

    }

    // if (loading) {
    //     return (
    //         <SpinWrap>
    //             {/* <Spin size="large"/> */}
    //         </SpinWrap>
    //        )
    // } else {
        return (
            <div
                style={{paddingTop: 50}}>
                <MainWrapper>
                    <CardWrapper>
                        <LogoContainer>
                            <div style={{
                                height: '50px',
                                width: '45px'
                            }}>
                                <Logo src={HEX}/>
                            </div>
                            <LabelWrapper>
                                <Label>Hex</Label>
                                <Label style={{color: '#AAAAAA'}}>Account</Label>
                            </LabelWrapper>
                        </LogoContainer>
                        <Title>Log in</Title>
                        <EditField onChange={(e) => handleChange(e)} type='email' placeholder='Email Email'/>
                        <EditField onChange={(e) => handleChange(e)} type='password' iconVisible
                                   placeholder='Password'/>
                        <Button buttonName='Log in' onClick={() => {
                            dispatch(loginUser(data))
                        }}/>
                        <OutlineButton
                            buttonName='Forgot your password?'
                            onClick={() => {
                                history.push('reset');
                            }}
                        />
                        <div style={{
                            justifyContent: 'center',
                            display: 'flex',
                            marginTop: '-15px'
                        }}>
                            <NewUserButton
                                userText='New User?'
                                buttonName='Create Account'
                                onClick={() => {
                                    history.push('signup');
                                }}
                            />
                        </div>
                    </CardWrapper>
                </MainWrapper>
            </div>
        );
    // }

}

export default Login;
