import React from 'react';
import { Row } from 'simple-flexbox';
import { StyleSheet, css } from 'aphrodite';
import { colors } from '../../colors.js';
import HEX from '../../assets/HEX_Shadow.svg';

const styles = StyleSheet.create({
    container: {
        marginLeft: 32,
        marginRight: 32
    },
    title: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontFamily: 'GorditaMedium',
        fontSize: 18,
        lineHeight: '24px',
        letterSpacing: '0.4px',
        color: colors.textColor,
        opacity: 0.7,
        marginTop: 15,
        marginLeft: 12
    }
});

function LogoComponent() {
    return (
        <Row className={css(styles.container)} vertical="center">
            <img src={HEX} width='40px' height='40px' />
            <span className={css(styles.title)}>Hexmount</span>
        </Row>
        // <LogoWrapper>
        //     <img src={HEX} />
        //     <Label>Hexmount</Label>
        // </LogoWrapper>
    );
}

export default LogoComponent;
