import styled from 'styled-components';
import { colors } from '../../colors';

const width = window.innerWidth - 30;

export const BottomWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    bottom: 30px;
    width: 40%;
    font-family: 'GorditaMedium';
    justify-content: space-between;

    @media (max-width: 1630px) {
        width: 55%;
    }

    @media (max-width: 1350px) {
        width: 65%;
    }

    @media (max-width: 1220px) {
        width: 100%;
    }

    @media (max-width: 450px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        display: flex;
        width: ${width}px;
    }
`;


export const Bottom = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    position: fixed;
    bottom: 30px;
    font-family: 'GorditaMedium';
    width: calc(100% - 355px);

    @media (max-width: 1100px) {
        display: none;
    }

    @media (max-width: 450px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        display: flex;
        width: ${width}px;
        bottom: 0px;
        background-color: ${colors.backgroundColor};
    }
`;

export const Copyright = styled.label`
    font-size: 12px;
    color: ${colors.grayLightColor1};
    // margin-right: 80px;

    @media (max-width: 450px) {
        margin-right: 0px;
    }
`;

export const Text = styled.label`
    font-size: 12px;
    color: ${colors.grayLightColor};
    // margin-right: 60px;

    @media (max-width: 450px) {
        margin-right: 0px;
    }
`;

export const Payments = styled.div`
    align-items: flex-end;
    display: flex;
    width: 60%;
    justify-content: center;
`;