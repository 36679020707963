import React from 'react';
import {bool, string} from 'prop-types';
import {Row} from 'simple-flexbox';
import {css, StyleSheet} from 'aphrodite';
import {colors} from '../../colors.js';

import {Dashboard} from '@styled-icons/material-sharp/Dashboard';
import {CreditCard} from '@styled-icons/entypo/CreditCard';
import {Milestone} from '@styled-icons/octicons/Milestone';
import {ProjectDiagram} from '@styled-icons/fa-solid/ProjectDiagram'
import {DesignServices} from '@styled-icons/material-rounded/DesignServices'


const styles = StyleSheet.create({
    activeBar: {
        height: 56,
        width: 7,
        backgroundColor: colors.mainColor,
        position: 'absolute',
        left: 0
    },
    activeContainer: {
        backgroundColor: colors.activeMenuColor
    },
    activeTitle: {
        color: colors.textColor,
    },
    container: {
        height: 56,
        cursor: 'pointer',
        ':hover': {
            backgroundColor: colors.activeMenuColor
        },
        paddingLeft: 32,
        paddingRight: 32
    },
    title: {
        fontSize: 15,
        lineHeight: '20px',
        letterSpacing: '0.2px',
        fontFamily: 'GorditaMedium',
        color: colors.textColor,
        marginLeft: 10,
        marginTop: 5
    }
});

function MenuItemComponent(props) {
    const {active, icon, title, ...otherProps} = props;
    return (
        <Row className={css(styles.container, active && styles.activeContainer)} vertical="center" {...otherProps}>
            {active && <div className={css(styles.activeBar)}>
            </div>}
            {icon === 'dashboard' &&
            <DesignServices size={25} color={colors.textColor}/>}
            {icon === 'card' &&
            <CreditCard size={25} color={colors.textColor}/>}
            {icon === 'milestone' &&
            <Milestone size={25} color={colors.textColor}/>}
            {icon === 'proposals' &&
            <ProjectDiagram size={25} color={colors.textColor}/>}




            <span className={css(styles.title, active && styles.activeTitle)}>{title}</span>
        </Row>
    );
}

MenuItemComponent.propTypes = {
    active: bool,
    icon: string,
    title: string
};

export default MenuItemComponent;
