import styled from 'styled-components';
import { colors } from '../../colors';
const deviceWidth = window.innerWidth;
const deviceHeight = window.innerHeight;
const mobileWidth = '450px';

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: row;

    .pro-sidebar {
        height: 100vh !important;
    }

    .pro-sidebar-inner {
        background: white;
    }

    .pro-menu-item {
        height: 70px;
        display: flex;
    }

    .pro-menu-item.active {
        background-color: ${colors.activeMenuColor};
        border-left: solid 8px ${colors.mainColor};
    }

    .side_wrapper {
        position: relative;
    }

    @media (max-width: 768px) {
        .burgerIcon_16yhg01 {
            z-index: 10000;
            position: fixed !important;
        }
    }

    @media (max-width: 450px) {
        .burgerIcon_16yhg01 {
            z-index: 10000;
            position: fixed !important;
        }
        .side_wrapper {
            z-index: 10000;
            position: fixed !important;
        }
    }

`;

export const ContentWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    padding-top: 90px;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 100px;
    flex-wrap: wrap;
    justify-content: flex-start;

    @media (max-width: 450px) {
        padding-top: 120px;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
    }
`;
