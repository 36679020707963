import React from 'react';
import {
    RegisterWrapper,
    NewUserLabel,
    NewButton
} from './button.style';
import HEX from '../../assets/HEX.svg';
import EditField from '../edit/edit';

class Button extends React.Component {

    render() {
        return (
            <RegisterWrapper>
                <NewUserLabel>{this.props.userText}</NewUserLabel>
                <NewButton onClick={this.props.onClick}>{this.props.buttonName}</NewButton>
            </RegisterWrapper>
        );
    }
}

export default Button;
