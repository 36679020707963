import styled from 'styled-components';
import { colors } from '../../colors';

export const ButtonWrapper = styled.button`
    height: 40px;
    background: transparent;
    border: none;
    font-size: 14px;
    color: ${colors.mainColor};
    outline: none;
    cursor: pointer;
    margin-top: 20px;
    font-family: 'GorditaMedium';
`;