import styled from 'styled-components';
import { colors } from '../../colors';

const deviceWidth = window.innerWidth;
const deviceHeight = window.innerHeight;
const mobileWidth = '450px';

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const CardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 500px;
   height: 760px !important;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow:
        0 2px 2px rgba(0,0,0, 0.03),
        0 4px 4px rgba(0,0,0, 0.03),
        0 8px 8px rgba(0,0,0, 0.03),
        0 16px 16px rgba(0,0,0, 0.03);

    @media (max-width: 450px) {
        margin-left: 10px;
        margin-right: 10px;
        height: ${deviceHeight - 100}px;
    }
`;

export const LogoContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 55px;
    align-items: flex-end;
    margin-top: 15px;
    justify-content: center;
`;

export const LabelWrapper = styled.div`
    display: flex;
    margin-top: 20px;
`;

export const Logo = styled.img`
    height: 45px;
    width: 45px;
`;


export const Label = styled.label`
    font-size: 23px;
    color: ${colors.textColor};
    margin-left: 10px;
    font-family: 'GorditaMedium';
`;

export const Title = styled.label`
    font-size: 26px;
    color: ${colors.textColor};
    font-family: 'GorditaBold';
    margin-top: 180px;
    margin-bottom: 20px;
    text-align: center;

    @media (max-width: 450px) {
        margin-top: 100px;
    }
`;
