import React from 'react';
import {EditWrapper, IconProvider, Input} from './edit.style';
import {Eye} from '@styled-icons/feather/Eye';
import {EyeOff} from '@styled-icons/feather/EyeOff';
import {colors} from '../../colors';

class EditField extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            borderColor: colors.editBorderColor,
            isShown: false
        }
    }

    render() {
        if (this.props.type === 'password')
            return (
                <EditWrapper style={{borderColor: this.state.borderColor}}>
                    <Input
                        name={"password"}
                        type={this.state.isShown ? 'text' : 'password'}
                        placeholder={this.props.placeholder ? this.props.placeholder : ''}
                        onChange={this.props.onChange}
                        onFocus={() => {
                            this.setState({
                                borderColor: colors.editHoverColor
                            })
                        }}
                        onBlur={() => {
                            this.setState({
                                borderColor: colors.editBorderColor
                            })
                        }}
                    >
                    </Input>
                    {
                        this.props.iconVisible ?
                            <IconProvider onClick={() => {
                                this.setState({
                                    isShown: !this.state.isShown
                                })
                            }}>
                                {
                                    this.state.isShown ?
                                        <EyeOff size={25}/> :
                                        <Eye size={25}/>

                                }
                            </IconProvider> : null
                    }
                </EditWrapper>
            );
        if (this.props.type === 'name')
            return (
                <EditWrapper style={{borderColor: this.state.borderColor}}>
                    <Input
                        name={"name"}
                        type={this.state.isShown ? 'text' : 'name'}
                        placeholder={this.props.placeholder ? this.props.placeholder : ''}
                        onChange={this.props.onChange}
                        onFocus={() => {
                            this.setState({
                                borderColor: colors.editHoverColor
                            })
                        }}
                        onBlur={() => {
                            this.setState({
                                borderColor: colors.editBorderColor
                            })
                        }}
                    >
                    </Input>
                    {
                        this.props.iconVisible ?
                            <IconProvider onClick={() => {
                                this.setState({
                                    isShown: !this.state.isShown
                                })
                            }}>
                                {
                                    this.state.isShown ?
                                        <EyeOff size={25}/> :
                                        <Eye size={25}/>

                                }
                            </IconProvider> : null
                    }
                </EditWrapper>
            );
        if (this.props.type === 'phone')
            return (
                <EditWrapper style={{borderColor: this.state.borderColor}}>
                    <Input
                        name={"phone"}
                        type={this.state.isShown ? 'text' : 'phone'}
                        placeholder={this.props.placeholder ? this.props.placeholder : ''}
                        onChange={this.props.onChange}
                        onFocus={() => {
                            this.setState({
                                borderColor: colors.editHoverColor
                            })
                        }}
                        onBlur={() => {
                            this.setState({
                                borderColor: colors.editBorderColor
                            })
                        }}
                    >
                    </Input>
                    {
                        this.props.iconVisible ?
                            <IconProvider onClick={() => {
                                this.setState({
                                    isShown: !this.state.isShown
                                })
                            }}>
                                {
                                    this.state.isShown ?
                                        <EyeOff size={25}/> :
                                        <Eye size={25}/>

                                }
                            </IconProvider> : null
                    }
                </EditWrapper>
            );
        return (
            <EditWrapper style={{borderColor: this.state.borderColor}}>
                <Input
                    name={"email"}
                    type={this.props.type}
                    onChange={this.props.onChange}
                    placeholder={this.props.placeholder ? this.props.placeholder : ''}
                    onFocus={() => {
                        this.setState({
                            borderColor: colors.editHoverColor
                        })
                    }}
                    onBlur={() => {
                        this.setState({
                            borderColor: colors.editBorderColor
                        })
                    }}
                >
                </Input>
            </EditWrapper>
        );
    }
}

export default EditField;
