import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  MainWrapper,
  CardWrapper,
  LogoContainer,
  LabelWrapper,
  Logo,
  Label,
  Title
} from './password.style';
import { colors } from '../../colors';
import HEX from '../../assets/HEX_Shadow.svg';
import EditField from '../../components/edit/edit';
import Button from '../../components/button/button';
import PasswordStrengthBar from 'react-password-strength-bar';
import OutlineButton from '../../components/outline_button/button';
import NewUserButton from '../../components/new_user_button/button';

class Password extends React.Component {
  // history = useHistory();

  constructor(props) {
    super(props);
    this.state = {
      password: ''
    }
  }

  render() {
    return (
      <div style={{paddingTop: 50}}>
        <MainWrapper>
          <CardWrapper>
            <LogoContainer>
            <div style={{
              height: '50px',
              width: '45px'
            }}>
              <Logo src={HEX} />
            </div>
              <LabelWrapper>
                <Label>Hex</Label>
                <Label style={{ color: '#AAAAAA' }}>Account</Label>
              </LabelWrapper>
            </LogoContainer>
            <Title>Create your password</Title>
            <div style={{
              textAlign: 'center',
              color: colors.textColor,
              fontFamily: '"GorditaRegular"',
              fontSize: '12px',
              marginBottom: '20px'
            }}>For <b style={{
                fontSize: '14px',
                color: 'black',
                fontFamily: '"GorditaBold"',
                marginLeft: '5px'
              }}
              >meid@hexmount.io</b></div>
            <EditField
              type='password'
              onChange={(e) => {
                this.setState({
                  password: e.target.value
                })
              }}
              iconVisible
              placeholder='Password' />
            <div style={{
                  marginTop: '20px',
                  marginBottom: '5px'
            }}>
              <div style={{marginBottom: 10}}><label style={{
                fontSize: '12px',
                fontFamily: '"GorditaRegular"',
                color: colors.textColor
              }}>Password strength</label></div>
              <PasswordStrengthBar
                password={this.state.password}
                shortScoreWord={false}
                scoreWords={false}
              />
            </div>
            <div style={{
              fontSize: '12px',
              marginTop: '10px',
              marginBottom: '20px',
              fontFamily: '"GorditaRegular"',
              color: colors.textColor
            }}>
              A strong password has more than 8 characters, containing uppercase and lowercase letters as well as symbols. Never reuse old passwords as this may compromise your account.
            </div>
            <Button buttonName='Create Password' onClick={() => {
              this.props.history.push('dashboard')
            }} />
          </CardWrapper>
        </MainWrapper>
      </div>
    );
  }

}

export default Password;
