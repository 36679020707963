import React, {Component} from 'react';
import {Select} from 'grommet';
import {connect} from 'react-redux';


class MileSelect extends Component {
    state = {value: [], options: this.props.milestones}

    render() {
        const {options, value} = this.state;
        return (
            <Select
                theme={{"color": "active", "opacity": "medium"}}
                multiple={false}
                value={value}
                onChange={event => this.setState({
                    value: event.value,
                    options: options,
                })}
                options={options}
            />
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    milestones: state.milestones.userMilestones
});

export default connect(mapStateToProps, {})(MileSelect)