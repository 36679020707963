import styled from 'styled-components';
import { colors } from '../../colors';

const osType = navigator.platform.substring(0, 3);

export const CardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 190px;
    width: 21%;
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    padding-bottom: 0px;
    margin: 10px;
    box-shadow:
        0 2px 2px rgba(0,0,0, 0.03),
        0 4px 4px rgba(0,0,0, 0.03),
        0 8px 8px rgba(0,0,0, 0.03),
        0 16px 16px rgba(0,0,0, 0.03);

    @media (max-width: 1680px) {
        width: 20%;
    }

    @media (max-width: 1595px) {
        width: 28%;
    }

    @media (max-width: 1459px) {
        width: 27%;
    }

    @media (max-width: 1282px) {
        width: 43%;
    }

    @media (max-width: 1200px) {
        width: 41%;
    }

    @media (max-width: 1019px) {
        width: 40%;
    }

    @media (max-width: 964px) {
        width: 100%;
    }

    @media (max-width: 768px) {
        width: 41%;
    }

    @media (max-width: 760px) {
        width: 40%;
    }

    @media (max-width: 710px) {
        width: 100%;
    }
`;

export const LogoContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 55px;
    align-items: flex-start;
    justify-content: space-between;
`;

export const LabelWrapper = styled.div`
    display: flex;
    width: 64px;
    height: 25px;
    align-items: center;
    justify-content: center;
    background-color: ${colors.activeProjectBackColor};
    border-radius: 13px;
`;

export const LabelCloseWrapper = styled.div`
    display: flex;
    width: 64px;
    height: 25px;
    align-items: center;
    justify-content: center;
    background-color: ${colors.closeProjectBackColor};
    border-radius: 13px;
`;

export const Logo = styled.img`
    height: 55px;
    width: 55px;
`;


export const Label = styled.label`
    font-size: 12px;
    color: ${colors.activeProjectColor};
    font-family: 'GorditaMedium';
    margin-top: ${osType === 'Mac' ? '4px' : '0px'}
`;

export const CloseLabel = styled.label`
    font-size: 12px;
    color: ${colors.closeProjectColor};
    font-family: 'GorditaMedium';
    margin-top: ${osType === 'Mac' ? '4px' : '0px'}
`;

export const ProjectWrapper = styled.div`
    font-family: 'GorditaMedium';
    font-size: 30px;
    margin-top: 30px;
    height: 42px;
    color: ${colors.textColor};
`;

export const ButtonWrapper = styled.div`
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-left: -20px;
    margin-right: -20px;
`;

export const LButton = styled.div`
    width: 50%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    border-top: solid 1px #F6F6F6;
    border-right: solid 1px #F6F6F6;
`;

export const RButton = styled.div`
    width: 50%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    border-top: solid 1px #F6F6F6;
`;

export const OutlineButton = styled.button`
    height: 40px;
    background: transparent;
    border: none;
    font-size: 14px;
    color: ${colors.mainColor};
    outline: none;
    cursor: pointer;
    font-family: 'GorditaMedium';
`;