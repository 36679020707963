import axios from 'axios';
import {headers} from './headers';
import {
    CLEAR_ERRORS,
    CREATE_PROJECT,
    GET_ERRORS,
    GET_PROJECT_MILESTONES,
    GET_USER_PROJECTS,
    PROJECT_LOADING,
} from '../types/types';

import {API_URL} from './global';


// {{const baseURL2 = API_URL2;

// create category
export const createProject = (projectdata) => (dispatch) => {
    dispatch(clearErrors());
    dispatch({type: PROJECT_LOADING})
    axios
        .post(API_URL + '/project/add', projectdata, headers())
        .then((res) =>
            dispatch({
                type: CREATE_PROJECT,
                payload: res.data
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};


export const getUserProjects = (userdata) => (dispatch) => {
    dispatch(clearErrors());
    dispatch({
        type: PROJECT_LOADING,
        payload: null
    })
    axios
        .post(API_URL + '/project/me', userdata, headers())
        .then((res) =>
            dispatch({
                type: GET_USER_PROJECTS,
                payload: res.data
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const getProjectMilestones = (userdata) => (dispatch) => {
    dispatch(clearErrors());
    dispatch({type: PROJECT_LOADING})
    axios
        .post(API_URL + '/project/milestone', userdata, headers())
        .then((res) =>
            dispatch({
                type: GET_PROJECT_MILESTONES,
                payload: res.data
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const clearErrors = () => {
    return {
        type: CLEAR_ERRORS
    };
};
