import {store} from '../store';

export const headers = () => {
	const authUser = store.getState();
	let Authorization = localStorage.getItem('jwtToken');

	let axiosConfigHeaders = {
		headers: {
			'Access-Control-Allow-Origin': '*',
			"Access-Control-Allow-Credentials": "true",
			'Content-Type': 'application/json',
			Authorization: `Bearer ${Authorization}`
			
		}
	};
	return axiosConfigHeaders;
};
export default headers;
