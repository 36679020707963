import styled from 'styled-components';
import { colors } from '../../colors';

export const EditWrapper = styled.div`
    height: 48px;
    display: flex;
    background-color: #E9EFFD;
    flex-direction: row;
    border-width: 0.5px;
    border-radius: 5px;
    border: solid 0.5px;
    margin-top: 8px;
    margin-bottom: 7px
`;

export const Input = styled.input`
    width: 90%;
    border: none;
    background-color: transparent;
    outline: none;
    font-size: 16px;
    font-family: 'GorditaRegular';
    padding-left: 10px;
    padding-top: 12px;
`;

export const IconProvider = styled.button`
    width: 10%;
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
`;