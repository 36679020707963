import axios from 'axios';
import {headers} from './headers';
import {
    CLEAR_ERRORS,
    CREATE_MILESTONE,
    CREATE_PAYMENT_SESSION,
    GET_ERRORS,
    GET_USER_MILESTONES,
    MILESTONE_LOADING,
} from '../types/types';

import {API_URL} from './global';


// {{const baseURL2 = API_URL2;

export const CreateMilestoneLink = (milestoneData) => (dispatch) => {
    axios
        .post(API_URL + '/pay/checkout', milestoneData, headers())
        .then((res) => {
                dispatch({
                    type: CREATE_PAYMENT_SESSION,
                    payload: res.data.data
                })
            // console.log(res.data.data)
            return res.data.data
            }
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );

};


// create category
export const CreateMilestone = (milestoneData) => (dispatch) => {
    dispatch(clearErrors());
    dispatch({type: MILESTONE_LOADING})
    axios
        .post(API_URL + '/milestone/add', milestoneData, headers())
        .then((res) =>
            dispatch({
                type: CREATE_MILESTONE,
                payload: res.data
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};


export const GetUserMilestones = (milestoneData) => (dispatch) => {
    dispatch(clearErrors());
    dispatch({
        type: MILESTONE_LOADING,
        payload: null
    })
    axios
        .post(API_URL + '/milestones/me', milestoneData, headers())
        .then((res) =>
            dispatch({
                type: GET_USER_MILESTONES,
                payload: res.data
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};


export const clearErrors = () => {
    return {
        type: CLEAR_ERRORS
    };
};
