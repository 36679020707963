import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  MainWrapper,
  CardWrapper,
  LogoContainer,
  LabelWrapper,
  Logo,
  Label,
  Title,
  PolicyText
} from './reset.style';
import HEX from '../../assets/HEX_Shadow.svg';
import EditField from '../../components/edit/edit';
import Button from '../../components/button/button';
import OutlineButton from '../../components/outline_button/button';
import NewUserButton from '../../components/new_user_button/button';

const Reset = () => {

  const history = useHistory();

  return (
    <div style={{paddingTop: 50}}>
      <MainWrapper>
        <CardWrapper>
          <LogoContainer>
          <div style={{
              height: '50px',
              width: '45px'
            }}>
              <Logo src={HEX} />
            </div>
            <LabelWrapper>
              <Label>Hex</Label>
              <Label style={{ color: '#AAAAAA' }}>Account</Label>
            </LabelWrapper>
          </LogoContainer>
          <Title>Reset password</Title>
          <PolicyText>
            You'll receive a link to reset your password
          </PolicyText>
          <EditField type='text' placeholder='Email address' />
          <Button buttonName='Send Reset Link' />
          <div style={{
            justifyContent: 'center',
            display: 'flex',
          }}>
            <OutlineButton buttonName='Back to Login'
              onClick={() => {
                history.goBack()
              }}
            />
          </div>
        </CardWrapper>
      </MainWrapper>
    </div>
  );

}

export default Reset;
