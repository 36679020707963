import React from 'react';

import {
    ButtonWrapper,
    CardWrapper,
    CloseLabel,
    Label,
    LabelCloseWrapper,
    LabelWrapper,
    LButton,
    Logo,
    LogoContainer,
    OutlineButton,
    ProjectWrapper,
    RButton
} from './card.style';
import HEX from '../../assets/HEX_Shadow.svg';

class Card extends React.Component {

    goToGitbook = () => {
        window.open(this.props.gitbook)
    }

    Milestone = () => {
        this.props.selectMile()
    }


    render() {
        let name = '';
        if (this.props.projectName.length > 15) {
            name = this.props.projectName.substring(0, 12) + '...'
        } else {
            name = this.props.projectName;
        }
        return (

            <CardWrapper>
                <LogoContainer>
                    <Logo src={HEX}/>
                    {
                        this.props.status === 'Live' || 'Dev' ?
                            <LabelWrapper>
                                <Label> {this.props.status}</Label>
                            </LabelWrapper> :
                            <LabelCloseWrapper>
                                <CloseLabel>{this.props.status}</CloseLabel>
                            </LabelCloseWrapper>
                    }
                </LogoContainer>
                <ProjectWrapper>{name}</ProjectWrapper>
                <ButtonWrapper>
                    <LButton target="_blank" onClick={this.goToGitbook}>

                        <OutlineButton>Documentation</OutlineButton>
                    </LButton>
                    <RButton onClick={this.Milestone}>
                        <OutlineButton>Milestones</OutlineButton>
                    </RButton>
                </ButtonWrapper>
            </CardWrapper>
        );
    }
}

export default Card;
