export const colors = {
    textColor: '#44474E',
    backgroundColor: '#F8F8F8',
    mainColor: '#5685F7',
    grayDarkColor: '#888A8F',
    grayLightColor: '#9B9CA0',
    grayLightColor1: '#BEBFC2',
    grayLightColor2: '#BABABA',
    editBackgroundColor: '#E9EFFD',
    editBorderColor: '#CFCFCF',
    editHoverColor: '#5685F7',
    activeMenuColor: '#EEF3FE',
    activeProjectColor: '#58BA5E',
    closeProjectColor: '#BA5858',
    activeProjectBackColor: '#EDF9EF',
    closeProjectBackColor: '#F9EFED',
}