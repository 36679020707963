import React from 'react';
import {
    ButtonWrapper
} from './button.style';
import HEX from '../../assets/HEX.svg';
import EditField from '../edit/edit';

class Button extends React.Component {

    render() {
        return (
            <ButtonWrapper onClick={this.props.onClick}>{this.props.buttonName}</ButtonWrapper>
        );
    }
}

export default Button;
