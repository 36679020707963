import styled from 'styled-components';
import { colors } from '../../colors';

export const LogoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Logo = styled.img`
    height: 40px;
    width: 40px;
`;

export const Label = styled.label`
    font-size: 17px;
    font-family: 'GorditaBold';
    color: ${colors.textColor};
    margin-left: 10px;
    margin-top: 15px;
`;