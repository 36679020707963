import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';
import jwt_decode from 'jwt-decode';

import {
	GET_ERRORS,
	SET_CURRENT_USER,
	REGISTER_USER,
	GET_PROFILE_BY_ID,
	CHANGE_PASSWORD,
	GET_CUSTOMER_PORTAL,
	PROFILE_LOADING
} from '../types/types';
import { API_URL } from './global';
import store from '../store';

const baseURL = API_URL;


// Register User
export const signup = (userData) => (dispatch) => {
	dispatch({type: PROFILE_LOADING})

	axios
		.post(baseURL + '/account/create', userData)
		.then((res) => {
			// Save to localStorage
			const token = res.data.data.token;
			// Set token to ls
			localStorage.setItem('jwtToken', token);
			// Set token to Auth header
			setAuthToken(token);
			// Decode token to get user data
			const decoded = jwt_decode(token);
			const new_decoded = {
				AccountType: decoded.AccountType,
				StripeId: decoded.StripeId,
				UserId: decoded.UserId,
				User_Email: res.data.data.email
			}
			// Set current user
			dispatch(setCurrentUser(new_decoded));
			localStorage.setItem('authUser', JSON.stringify(decoded));
			return res.data;
		})
		.catch((err) => {
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			});
		});
};


// Login - Get User Token
export const loginUser = (userData) => (dispatch) => {
	dispatch({type: PROFILE_LOADING})

	axios
		.post(baseURL + '/account/signin', userData)
		.then((res) => {
			// Save to localStorage
			const token = res.data.data.token;
			// Set token to ls
			localStorage.setItem('jwtToken', token);
			// Set token to Auth header
			setAuthToken(token);
			// Decode token to get user data
			const decoded = jwt_decode(token);
			// Set current user
			const new_decoded = {
				AccountType: decoded.AccountType,
				StripeId: decoded.StripeId,
				UserId: decoded.UserId,
				User_Email: res.data.data.email
			}
			dispatch(setCurrentUser(new_decoded));
			localStorage.setItem('authUser', JSON.stringify(decoded));
			return res.data;
		})
		.catch((err) => {
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			});
		});
};



export const GetUserById = (userData) => (dispatch) => {
	dispatch({type: PROFILE_LOADING})
	axios
		.post(baseURL + '/account/get/id', userData)
		.then((res) => {dispatch({
				type: GET_PROFILE_BY_ID,
				payload: res.data.data
			});
		})
		.catch((err) => {
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			});
		});
};


export const GetUserPortal = (userData) => (dispatch) => {
	dispatch({type: PROFILE_LOADING})

	axios
		.post(baseURL + '/account/create-portal', userData)
		.then((res) => {dispatch({
			type: GET_CUSTOMER_PORTAL,
			payload: res.data.data
		});
		})
		.catch((err) => {
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			});
		});
};
// Set logged in user
export const setCurrentUser = (decoded) => {
	return {
		type: SET_CURRENT_USER,
		payload: decoded
	};
};

// Log user out
export const logoutUser = () => (dispatch) => {
	// Remove token from localStorage
	localStorage.removeItem('jwtToken');
	localStorage.removeItem('authUser');
	// Remove auth header for future requests
	setAuthToken(false);
	// Set current user to {} which will set isAuthenticated to false
	dispatch(setCurrentUser({}));
};

// // Forgot Password
// export const forgotPassword = (userData) => (dispatch) => {
// 	axios
// 		.post(lambdaA + '/users/forgotEmail', userData)
// 		.then((res) => {
// 			if (res.data.status === 1) {
// 				dispatch({
// 					type: FORGOT_PASSWORD,
// 					payload: res.data
// 				});
// 			} else {
// 				dispatch({
// 					type: GET_ERRORS,
// 					payload: res
// 				});
// 			}
// 			return res;
// 		})
// 		.catch((err) => {
// 			//on error throw error;
// 			//throw error;
// 			dispatch({
// 				type: GET_ERRORS,
// 				payload: err.response
// 			});
// 			throw err;
// 		});
// };

// // Change Password
// export const changePassword = (userData) => (dispatch) => {
// 	const userID = store.default.getState().auth.user.id;
// 	axios
// 		.patch(lambdaA + '/users/changepassword/' + userID, userData)
// 		.then((res) => {
// 			if (res.data.status === 1) {
// 				dispatch({
// 					type: CHANGE_PASSWORD,
// 					payload: res.data
// 				});
// 			} else {
// 				dispatch({
// 					type: GET_ERRORS,
// 					payload: res
// 				});
// 			}
// 			return res;
// 		})
// 		.catch((err) => {
// 			//on error throw error;
// 			//throw error;
// 			dispatch({
// 				type: GET_ERRORS,
// 				payload: err.response
// 			});
// 			throw err;
// 		});
// };
