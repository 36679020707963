import styled from 'styled-components';
import {colors} from '../../colors';

const deviceWidth = window.innerWidth;
const deviceHeight = window.innerHeight;
const mobileWidth = '450px';

export const MainWrapper = styled.div`
    position: fixed; /* or absolute */
    top: 15%;
    left: 35%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const SpinWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: fixed; 
    top: 50%;
    left: 50%;
`;

export const CardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 500px;
    height: 600px ;
    padding: 20px;
    background-image: linear-gradient(135deg, #F1F1F1 0%, #FFFFFF 100%);
    box-shadow: 0 2px 12px 5px rgba(9,66,199,0.11);

    border-radius: 5px;

    @media (max-width: 450px) {
        margin-left: 10px;
        margin-right: 10px;
        height: ${deviceHeight - 100}px;
    }
`;

export const LogoContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 75px;
    align-items: flex-end;
    margin-top: 15px;
    justify-content: center;
`;

export const LabelWrapper = styled.div`
    display: flex;
    margin-top: 30px;
`;

export const Logo = styled.img`
    height: 85px;
    width: 85px;
`;
export const Button = styled.button`
    height: 49px;
    font-size: 16px;
    background: #0F42BA;
    color: white;
    outline: none;
    border-radius: 5px;
    border-width: 0px;
    cursor: pointer;
    margin-top: 35px;
   
    font-family: 'GorditaMedium';
    padding-top: 10px;

`;
export const CardImg = styled.img`
    height: 300px;
    width: 300px;
`;


export const Label = styled.label`
    font-size: 23px;
    font-family: 'GorditaMedium';
    color: ${colors.textColor};
    margin-left: 10px;
`;

export const Title = styled.label`
    font-size: 26px;
    font-family: 'GorditaBold';
    color: ${colors.textColor};
    margin-bottom: 20px;
    text-align: center;

    @media (max-width: 450px) {
        margin-top: 100px;
    }
`;
export const Text = styled.label`

    font-family: Helvetica;
    font-size: 15px;
    color: #132D6B;
    letter-spacing: 0;
    text-align: center;
    line-height: 22px;

    @media (max-width: 450px) {
        margin-top: 100px;
    }
`;
